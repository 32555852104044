@import "../../assets/styles/abstracts";

.table-section{
  border-collapse: collapse;
  width: 100%;
  border: none;
  
  &__thead{
    tr{
      text-align: left;
      
      th{
        padding: rem(10) rem(10) rem(10) rem(5);
        font-family: $font-light;
        font-weight: $lgWeight;
        font-size: rem(15);
      }
    }
  }
  &__tbody{
    tr{
      text-align: left;
      &:hover{
        background-color: $tableBorder;
      }
      td{
        border-bottom: 1px solid $tableBorder;
        padding: rem(10) rem(10) rem(10) rem(5);
        font-family: $font-light;
        font-family: 'Nunito', sans-serif;
     
        p{
          margin-bottom: 0;
          font-family: 'Nunito', sans-serif;
        }
      }
    }
  }
  &__tfoot{
    tr{
      text-align: left;
      background-color: $table-header-bg-color;
      th{
        padding: rem(10) rem(10) rem(10) rem(5);
        font-family: $font-light;
        
      }
    }
  }
}
