@import "../../assets/styles/abstracts";

.search{
  width: 100%;
  margin-bottom: rem(25);
  position: relative;
  input{
    width: 100%;
    outline: none;
    border: 1px solid #e5e5e5;
    padding: rem(10) rem(55) rem(10) rem(10);
  }
  img{
    position: absolute;
    right: rem(15);
    top: rem(10);
    width: rem(25);
    height: rem(25);
  }
}