@import "assets/styles/abstracts";

th {
  text-transform: capitalize;
}
.operation-btn {
  padding: 6px 8px;
  margin: 0 8px;
}
td {
  .table_icon{
    width: rem(25);
    height: rem(25);
    object-fit: contain;
  }

}
.edit_delete{
  width: rem(140) !important;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: rem(20) 0;


  li {
    padding: rem(3) rem(15);
    margin: rem(5);
    height: rem(32);
    width: rem(32);
    border-radius: $border-radius;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $lgWeight;
    font-size: $fontSizeSm;
    line-height: rem(18);
    box-shadow: $boxShadowCard;
    color: $darkColor;
    background-color: $white;
  }

  .disable {
    pointer-events: auto !important;
    cursor: not-allowed !important;
  }

  .btn--transparent {
    background-color: transparent;

    img {
      max-width: none;
      width: rem(10);
      height: rem(10);
    }
  }
  .prev_btn_div,
  .next_btn_div {
    box-shadow: none;
    margin: 0 rem(5);
   
    svg{
      width: rem(10);
      height: rem(15);
    }
  }

}

.pagi_active {
  background-color: $main;
  border-radius: $Bradius;
  background-color: $accColor !important;
}

.lang_toggle_list {
  display: flex;
  justify-content: start;
  align-items: center;
  &_item {
    transition: all 0.3s;
    margin-right: rem(36);
    font-size: rem(12);
    text-transform: uppercase;
    padding: rem(6) rem(8);
    border-radius: rem(6);
    &:hover {
      background-color: $left-menu-hover;
    }
    &.active {
      color: $opd_dark_blue;
    }
  }
}

