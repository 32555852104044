@import "../../../assets/styles/abstracts";

.category{
  &__pic{
    border: 1px solid $border;
    padding: rem(7) rem(10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      width: rem(55);
      height: rem(55);
      object-fit: cover;
    }
    svg{
      cursor: pointer;
    }
  }
  .ant-divider-inner-text{
    color: $txt-blue;
  }
  &__body{

  }
  &__group{
    width: 100%;
    display: flex;
    flex-direction: column;
    &__success{
      display: none;
    }
    &__error{
      display: block;
      color: red;
    }
    &__err{
      border: 1px solid red !important;
    }
  }
  &__btns{
    display: flex;
    gap: rem(15);
    justify-content: flex-end;
  }
  &__upload{
    label{
      margin-top: rem(5);
      border: 1px solid #d9d9d9;
      padding: rem(10);
      cursor: pointer;
    }
  }
}