@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;500;600;700;900&display=swap');
body {
  font-family: 'Nunito', sans-serif;
  margin: 0;
  background-color: $sidebar !important;
}
a {
  text-decoration: none;
  &:hover{
    color: black!important;
  }
}
img {
  max-width: 100%;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}


::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px $main;
}

::-webkit-scrollbar-thumb {
  background: $main;
}

