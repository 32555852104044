@import "../abstracts";

.r-input {
  font-family: $font-medium;
  letter-spacing: rem(1);
  color: $input-text-color;
  width: 100%;
  border: 1px solid $input-border-color;
  border-radius: rem(6);
  outline: none;
  padding: rem(8) rem(15);
  @include transition;

  &:focus {
    border: 1px solid $input-border-focus-color;
    box-shadow: 0 0 5px 2px $input-focus-shadow-color;
  }

  &::placeholder {
    opacity: 0.5;
  }

  &--dark {
    background-color: $input-dark;
    border: 1px solid $input-dark;
    color: $light;

    &:focus {
      border: 1px solid $input-dark;
      box-shadow: none;
    }
  }
}

.err-txt {
  color: red;
  font-size: rem(14);
}

.r-checkbox {
  width: rem(15);
  height: rem(15);
}

.r-select {
  letter-spacing: rem(1);
  color: $input-text-color;
  width: 100%;
  border: 1px solid $input-border-color;
  border-radius: rem(6);
  outline: none;

  &:focus {
    border: 1px solid $input-border-focus-color;
    box-shadow: 0 0 5px 2px $input-focus-shadow-color;
  }
}

.r-textarea {
  letter-spacing: rem(1);
  color: $input-text-color;
  width: 100%;
  border: 1px solid $input-border-color;
  border-radius: rem(6);
  outline: none;

  &:focus {
    border: 1px solid $input-border-focus-color;
    box-shadow: 0 0 5px 2px $input-focus-shadow-color;
  }
}

.second-label {
  color: $main;
  text-transform: uppercase;
  margin-bottom: rem(0);
}

.file-btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: $file ;
  position: relative;
 padding: 0;
  .bi-upload{
    margin-right: rem(15);
  }
  label {
    margin: 0;
    padding-left: rem(25);
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: $file_name;
    cursor: pointer;
    border: rem(2) solid transparent;
  }
  

  .file-input {
    width: 100%;
    cursor: pointer !important;
    z-index: 9999;
  }
}

.r-label {
  font-weight: 600;
  color: $label-color;
  font-size: rem(14);
  text-transform: capitalize;
}

#form_input{
  outline: none !important;
  border: none !important;
  cursor: pointer;
}