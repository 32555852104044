@import "./../../assets/styles/abstracts";

.btn {
  // padding: rem(3) rem(15);
  // text-shadow: none;
  // font-size: rem(14);
  // color: $main-button-text-color;
  // cursor: pointer;
  // background-color: $button-color;
  // will-change: opacity, transform;
  // border-radius: $border-radius;
  // outline: none;
  // border: none;
  // border: 1px solid;
  svg.bi{
    width: rem(20);
    height: rem(20);
  }
  svg.bi-pencil{
    color: $editColor;
  }
  svg.bi-trash{
    color: $trashColor;
  }
  &--secondary {
    color: $button-text-color !important;
    background-color: $secondary-button-color;
    border-color: $secondary-button-color;
    &:hover{
      background-color: $secondary-button-color;
      border-color: $secondary-button-color;
      opacity: 0.9;
    }
  }
  @include transition(all, 0.3s, ease-out);
  a {
    color: inherit;
  }
  &:hover {
    // box-shadow: none;
  }

}

.back-btn {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  margin: rem(12) rem(3); 
  svg{margin-right: rem(8);}
}
