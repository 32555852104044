@font-face {
  font-family: "PFSquareSansPro Bold";
  src: url("../../fonts/PFSquareSans/PFSquareSansPro-Bold.ttf");
}

@font-face {
  font-family: "PFSquareSansPro Light";
  src: url("../../fonts/PFSquareSans/PFSquareSansPro-Light.ttf");
}

@font-face {
  font-family: "PFSquareSansPro Medium";
  src: url("../../fonts/PFSquareSans/PFSquareSansPro-Medium.ttf");
}

@font-face {
  font-family: "PFSquareSansPro Regular";
  src: url("../../fonts/PFSquareSans/PFSquareSansPro-Regular.ttf");
}

@font-face {
  font-family: "PFSquareSansPro Thin";
  src: url("../../fonts/PFSquareSans/PFSquareSansPro-Thin.ttf");
}

@font-face {
  font-family: "PFSquareSansPro XBlack";
  src: url("../../fonts/PFSquareSans/PFSquareSansPro-XBlack.ttf");
}
